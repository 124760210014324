<template>
  <div class="main">
    <div class="wrapper">
      <div v-if="isLoading">
        <v-sheet height="100vh">
          <div class="flex">
            <div class="flex flex-column">
              <span class="text-center text-h5 green--text mt-3">
                Wrapping up your order
              </span>
              <div class="flex justify-center my-3">
                <v-progress-circular
                  indeterminate
                  color="green"
                ></v-progress-circular>
              </div>
            </div>
          </div>
        </v-sheet>
      </div>

      <!-- <component :is="recent.component" /> -->
      <transition name="fade">
        <component
          :is="navigation[franchise.list_style == 'list' ? 0 : 1].component"
        />
      </transition>
      <!-- <v-alert color="blue-grey" outlined icon="mdi-alpha-p-circle" v-if="franchise.policy && franchise.policy !== ''">
        {{ franchise.policy }}
      </v-alert> -->
      <div
        class="products_view_order_button"
        v-if="cart.length > 0 && !dialogHistory"
      >
        <v-btn
          width="25%"
          v-if="compareOrder.length > 0"
          dark
          color="#F85757"
          style="height: 65px; width: 50px"
          class="d-flex justify-space-center pa-2 pr-6 pl-6"
          rounded-xl
          elevation="0"
          @click="toggleHistory(true)"
        >
          <v-icon class="mr-2">mdi-history</v-icon>
        </v-btn>

        <v-btn
          :width="compareOrder.length > 0 ? '75%' : '100%'"
          color="#F85757"
          style="height: 65px"
          class="d-flex justify-space-between ml-3 pa-2 pr-6 pl-6"
          large
          dark
          rounded-xl
          elevation="0"
          @click="goToCart"
        >
          <!-- <v-icon class="mr-2">mdi-cart-plus</v-icon> -->
          <div style="text-transform: none; font-size: 15px">
            Cart : {{ renderQuantity(cart) }} Item
          </div>
          <div>{{ this.total.toFixed(2) }}</div>
        </v-btn>
      </div>
      <div
        class="history_sticky_button"
        v-if="!dialogHistory && compareOrder.length > 0 && cart.length == 0"
      >
        <v-btn
          block
          color="#F85757"
          style="height: 65px"
          class="d-flex justify-space-between pa-2 pr-6 pl-6"
          large
          dark
          rounded-xl
          elevation="0"
          @click="toggleHistory(true)"
        >
          <v-icon class="mr-2">mdi-history</v-icon>
          <div style="text-transform: none; font-size: 15px">History</div>
        </v-btn>
      </div>

      <div v-if="dialogHistory">
        <v-dialog
          transition="dialog-bottom-transition"
          v-model="dialogHistory"
          overlay-color="white"
          overlay-opacity="2"
          style="z-index: 1000 !important"
          max-width="500px"
        >
          <div v-if="compareOrder.length > 0" style="padding: 15px">
            <br />
            <v-card
              v-for="(v, i) in this.compareOrder.reverse()"
              :key="i"
              class="rounded-lg mx-auto mb-4"
              color="white"
              elevation="5"
              max-width="1150"
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline">
                    {{ v.datetime }}
                  </div>
                  <v-list-item-title class="text-h5 mb-5">
                    {{ v.cart[0].name }}
                    <v-list-item-title
                      class="text-subtitle sm-3"
                      v-if="v.cart[0].variant.length > 0"
                    >
                      Total: RM {{ currency(v.cart[0].variant[0].price) }} x
                      {{ v.cart[0].quantity
                      }}<!-- Use variant price -->
                    </v-list-item-title>
                    <v-list-item-title class="text-subtitle sm-3" v-else>
                      Total: RM {{ currency(v.cart[0].unit_price) }} x
                      {{ v.cart[0].quantity
                      }}<!-- Use unit_price -->
                    </v-list-item-title>
                    <v-list-item-title class="text-subtitle sm-3">
                      <div v-if="v.cart.length > 1">
                        and {{ v.cart.length - 1 }} more items
                      </div>
                    </v-list-item-title>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-avatar tile size="80" color="grey" class="mt-16">
                  <v-img
                    v-if="v.cart[0].media_urls.length > 0"
                    max-height="120"
                    max-width="140"
                    :src="image(v.cart[0].media_urls[0])"
                  ></v-img>
                  <v-img
                    v-else
                    class="rounded-l-lg"
                    max-height="120"
                    max-width="140"
                    :src="emptyImage()"
                  ></v-img>
                </v-list-item-avatar>
              </v-list-item>

              <v-container grid-list-xs class="px-0 pb-0 mt-0">
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <v-card
                      color="white"
                      class="rounded-t-xl pa-3 pt-0 mt-2"
                      elevation="0"
                    >
                      <div>
                        <v-divider class="my-4"></v-divider>
                        <v-row v-for="(v, i) in status.cart" :key="i">
                          <v-col cols="12" class="py-0">
                            <v-card color="white" class="rounded-lg mt-3 pa-3">
                              <v-row>
                                <v-col cols="4" lg="2" class="my-auto">
                                  <v-img
                                    v-if="v.media_urls.length > 0"
                                    class="rounded-l-lg"
                                    max-height="120"
                                    max-width="140"
                                    :src="image(v.media_urls[0])"
                                  ></v-img>
                                  <v-img
                                    v-else
                                    class="rounded-l-lg"
                                    max-height="120"
                                    max-width="140"
                                    :src="emptyImage()"
                                  ></v-img>
                                </v-col>
                                <v-col cols="6" lg="9">
                                  <div
                                    class="flex flex-column justify-space-between h"
                                  >
                                    <div
                                      class="flex flex-column source grey--text"
                                      style="line-height: 1.2"
                                    >
                                      <span
                                        class="flex flex-column black--text"
                                      >
                                        <h5>{{ v.tags[0].name }}</h5>
                                        <h3>{{ v.name }}</h3>
                                        <div
                                          v-if="v.modifiers.length > 0"
                                          class=""
                                        >
                                          <span
                                            class="flex"
                                            v-for="(val, ind) in v.modifiers"
                                            :key="ind"
                                          >
                                            <h5>{{ showMod(val) }}</h5>
                                          </span>
                                        </div>
                                        <!-- {{v.variant}} -->
                                        <div
                                          v-if="
                                            v.variant[0] && v.price_type == 0
                                          "
                                          class=""
                                        >
                                          <span
                                            class="flex"
                                            v-for="(
                                              values, indexs
                                            ) in v.variant"
                                            :key="indexs"
                                          />
                                        </div>
                                        <div
                                          v-if="
                                            v.variant[0] && v.price_type == 0
                                          "
                                          class=""
                                        >
                                          <span
                                            class="flex"
                                            v-for="(
                                              values, indexs
                                            ) in v.variant"
                                            :key="indexs"
                                          >
                                            <h5>{{ showAmt(values) }}</h5>
                                          </span>
                                        </div>
                                        <h5
                                          class="font-weight-bold"
                                          v-if="v.remarks"
                                        >
                                          Remarks:
                                        </h5>
                                        <h5
                                          class="font-weight-bold blue-grey--text"
                                          v-if="v.remarks"
                                        >
                                          {{ v.remarks }}
                                        </h5>
                                      </span>
                                    </div>
                                    <div
                                      class="flex black--text flex-column justify-end"
                                    >
                                      <h3 class="mt-1">
                                        RM
                                        {{
                                          v.price_type != 0
                                            ? currency(v.unit_price)
                                            : currency(v.variant[0].price)
                                        }}
                                      </h3>
                                    </div>
                                  </div>
                                </v-col>
                                <v-col
                                  cols="2"
                                  lg="1"
                                  class="flex flex-column justify-space-between"
                                >
                                  <v-btn color="gold" x-small fab elevation="0">
                                    <h3 class="font-weight-bold text-lowercase">
                                      x {{ v.quantity }}
                                    </h3>
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-col>
                        </v-row>
                        <!-- summary -->
                        <v-row>
                          <v-col cols="12" class="pb-0">
                            <v-card class="rounded-lg" elevation="0">
                              <div class="pa-3 source black--text">
                                <h2>Summary</h2>
                                <v-divider></v-divider><br />
                                <div class="flex justify-space-between">
                                  <h2>Total</h2>
                                  <h2 class="lato">
                                    RM
                                    {{ currency(v.totalOrder) }}
                                  </h2>
                                </div>
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12">
                            <div></div>
                          </v-col>
                        </v-row>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <v-btn
              block
              color="#F85757"
              style="height: 40px"
              class="d-flex justify-space-between pa-2 pr-6 pl-6"
              large
              dark
              rounded-xl
              elevation="0"
              @click="toggleHistory(false)"
            >
              <!-- <v-icon class="mr-2">mdi-cart-plus</v-icon> -->
              <div style="text-transform: none; font-size: 15px">Close</div>
            </v-btn>
            <!-- end of history detail -->
          </div>
        </v-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Product",
  data() {
    return {
      total: 0,
      isLoading: false,
      status: null,
      dialogHistory: false,
      navigation: [
        { title: "list", component: () => import("./List") },
        { title: "grid", component: () => import("./Grid") },
        { title: "slide", component: () => import("./Slide") },
      ],
      // recent: { component: () => import("./Recent") },
    };
  },

  computed: {
    ...mapGetters({
      franchise: "getFranchise",
      user: "getUsers",
      cart: "getCart",
      order: "getOrder",
    }),
    selectedUser() {
      return this.user.find((d) => d.selected == true);
    },
    compareOrder() {
      if (!this.selectedUser) return [];
      return this.order.filter(
        (d) => d.customer.name == this.selectedUser.name,
      );
    },
    dayjs() {
      return require("dayjs");
    },
    currency() {
      return require("currency.js");
    },
  },

  watch: {
    cart: {
      handler: "calculate",
      immediate: true,
    },
  },
  methods: {
    image(i) {
      if (i.s512) {
        return i.s512;
      } else
        return (
          "https://s3-ap-southeast-1.amazonaws.com/assets.getorders/" +
          i.original
        );
    },
    emptyImage() {
      return (
        "https://s3-ap-southeast-1.amazonaws.com/assets.getorders/" +
        this.franchise.logo
      );
    },
    async calculate() {
      try {
        const total = await this.$store.dispatch("cartTotal");
        this.total = total;
      } catch (error) {
        console.error("Error Calculating total", error);
      }
    },
    toggleHistory(status) {
      console.log(JSON.parse(JSON.stringify(this.status)));
      console.log(status);
      this.dialogHistory = status;
    },
    renderQuantity(carts) {
      let totalSum = 0;

      carts.forEach((obj) => {
        totalSum += obj.quantity;
      });
      return totalSum;
    },
    goToCart() {
      const { outlet_id, table_id } = this.$route.params;
      const { session } = this.$route.params;
      if ((outlet_id, table_id)) {
        // STATIC QR
        this.$router.push({
          name: "Cart",
          params: {
            child: this.$route.params.child,
            outlet_id: outlet_id,
            table_id: table_id,
            page: "cart",
          },
        });
      } else if (session) {
        // SESSION QR
        this.$router.push({
          name: "SessionCart",
          params: { session },
        });
      } else {
        // SHOPL LINK
        this.$router.push({
          name: "ShoplinkCart",
          params: { child: this.$route.params.child, page: "cart" },
        });
      }
    },
  },

  beforeMount() {
    if (this.order) {
      this.status = this.order;
    }
  },
};
</script>
<style lang="scss">
.products_view_order_button {
  position: sticky;
  bottom: 0px;
  padding-top: 10px;
  display: flex;
  padding-left: 10px;
  background-color: white;
  padding-bottom: 20px;
  padding-right: 15px;
  z-index: 1000000;
  width: 100%;
}

.history_sticky_button {
  position: sticky;
  bottom: 0px;
  padding-top: 10px;
  padding-left: 10px;
  background-color: white;
  padding-bottom: 20px;
  padding-right: 15px;
  z-index: 1000000;
  width: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
